/* eslint-disable react/no-unknown-property */
// import '@/js/wes/wes-react';

import React from 'react';
// import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SearchBanner from '@/js/components/redesign/banner';
import CategoryCard from '@/js/components/redesign/categoryCard';
import Header from '@/js/components/redesign/header';
import { Category } from '@/js/store/products/reducer';
import { selectVisibleParentCategories } from '@/js/store/products/selectors';
// import Automotive from '@/img/Automotive.png';

const Landing = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const site_name = window.GLOBALS?.SITE?.name || t('MetaDeploy'); // default for page title
  const page_title = `${t('Find Solutions')} | ${site_name}`; // default for page title
  const welcome_text = window.GLOBALS.SITE?.welcome_text;
  const welcome_heading = window.GLOBALS.SITE?.welcome_heading;

  // Since we are collecting window globals might as well do the seach heading here
  const search_heading = window.GLOBALS.SITE?.search_heading;

  const productCategories: Category[] = useSelector(
    selectVisibleParentCategories,
  );

  const welcomeHeading = () =>
    welcome_heading ? (
      <h2 type-style="display-5" className="slds-p-bottom_medium">
        {welcome_heading}
      </h2>
    ) : null;

  const welcomeText = () =>
    welcome_text ? (
      <p
        type-style="body-1"
        dangerouslySetInnerHTML={{ __html: welcome_text }}
      ></p>
    ) : null;

  const parentCategoryList = () =>
    productCategories.map((cat: Category) => (
      <CategoryCard category={cat} key={cat.id} />
    ));

  return (
    <>
      <Helmet>
        <title>{page_title}</title>
      </Helmet>

      <Header history={history} />

      <SearchBanner
        searchHeader={search_heading}
        searchPlaceholder={t('Search for Industries, products and more...')}
      />

      <div className="content-main slds-p-bottom_x-large">
        <div className="slds-grid slds-grid_align-center slds-gutters">
          <div className="section-intro slds-col slds-text-align_center slds-size_11-of-12 slds-medium-size_9-of-12 slds-large-size_8-of-12">
            {welcomeHeading()}
            {welcomeText()}
          </div>
        </div>

        {/* Having issues using WES-Grid, sticking with SLDS Grid */}
        <div className="slds-grid slds-wrap slds-grid_align-center">
          <div className="slds-grid slds-size_8-of-12 slds-wrap slds-grid_align-center">
            {parentCategoryList()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
