/* eslint-disable react/no-unknown-property */
import '@/js/wes/wes-react';

import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import ProductIcon from '@/js/components/redesign/productIcon';
import { Product } from '@/js/store/products/reducer';
import { LATEST_VERSION } from '@/js/utils/constants';
import routes from '@/js/utils/routes';

interface SolutionCardProps {
  product: Product;
}

const SolutionCard = ({ product }: SolutionCardProps) => {
  const { title, description, slug, short_description } = product;
  const location = useLocation();

  return (
    // Its important we link to the proper URL for the location to work
    <Link
      className="solution-card slds-text-link_reset slds-grid slds-has-flexi-truncate"
      to={{
        pathname: routes.version_details(slug, LATEST_VERSION),
        state: { from: location.pathname },
      }}
    >
      <wes-card media="no-fill" depth="2">
        <div slot="header-start" className="image-container">
          {/* <img src={FinancialServices} width="50px" height="50px" />*/}
          <ProductIcon item={product} />
        </div>
        <h3
          type-style="display-7"
          slot="header"
          className="slds-line-clamp_x-small"
          title={title}
        >
          {title}
        </h3>
        <div slot="header-end" className="badge-container ">
          <wes-badge>
            <span className="slds-truncate">
              Version {product.most_recent_version?.label}
            </span>
          </wes-badge>
        </div>
        <div
          className="slds-line-clamp_x-small"
          type-style="body-2"
          title={short_description || description}
          dangerouslySetInnerHTML={{
            __html: short_description || description,
          }}
        ></div>
      </wes-card>
    </Link>
  );
};

export default React.memo(SolutionCard);
