/* eslint-disable react/no-unknown-property */
import Combobox from '@salesforce/design-system-react/components/combobox';
import comboboxFilterAndLimit from '@salesforce/design-system-react/components/combobox/filter';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LandingBanner from '@/img/landing-banner.png';
import {
  selectVisibleCategoriesWithProducts,
  selectVisibleParentCategories,
} from '@/js/store/products/selectors';
import routes from '@/js/utils/routes';

interface SearchBannerProps {
  searchHeader: string;
  searchPlaceholder: string;
}

type SearchEntry = {
  id: string;
  label: string;
  subTitle: string;
  productSlug: string;
};

type ParentName = {
  [key: number]: string;
};

const bannerBkg = {
  backgroundImage: `url('${LandingBanner}')`,
};

const SearchBanner = ({
  searchHeader,
  searchPlaceholder,
}: SearchBannerProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [inputValue, setInputValue] = useState('');

  const parentTitles: ParentName = {};
  useSelector(selectVisibleParentCategories).forEach((cat) => {
    parentTitles[cat.id] = cat.title;
  });

  const search_entries: SearchEntry[] = [];
  useSelector(selectVisibleCategoriesWithProducts).forEach((catWProd) => {
    const category = catWProd.category;
    catWProd.products.forEach((prod) => {
      search_entries.push({
        id: prod.id,
        label: prod.title,
        subTitle: category.parent
          ? `${parentTitles[category.parent]} • ${category.title}`
          : `${category.title}`,
        productSlug: prod.slug,
      });
    });
  });

  const searchHeading = () =>
    searchHeader ? (
      <div className="slds-col slds-size_10-of-12 slds-large-size_8-of-12 slds-text-align_center">
        <h1 type-style="display-2">{searchHeader}</h1>
      </div>
    ) : null;

  return (
    <div className="landing-banner" style={bannerBkg}>
      <div className="banner-foreground slds-grid slds-wrap slds-grid_vertical-align-center  slds-grid_align-center">
        {searchHeading()}

        <div className="slds-col slds-size_10-of-12 slds-large-size_7-of-12 slds-text-align_center">
          <div className="slds-col">
            <Combobox
              id="combobox-landing-search"
              events={{
                onFocus: () => null,
                onChange: (event: any, { value }: any) => {
                  setInputValue(value);
                },
                onSelect: (
                  event: any,
                  data: { [x: string]: any; selection?: any },
                ) => {
                  history.push(
                    routes.product_details(data.selection[0].productSlug),
                  );
                },
              }}
              labels={{
                placeholder: searchPlaceholder,
                noOptionsFound: t(
                  `No options found for search term ${inputValue}`,
                ),
              }}
              // this is a single select combobox, so no need to track selection
              options={comboboxFilterAndLimit({
                inputValue,
                options: search_entries,
                selection: [],
              })}
              value={inputValue}
              variant="base"
              assistiveText={{
                label: 'Search for Industries, products and more',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBanner;
