import { Product, Version } from '@/js/store/products/reducer';
import { LATEST_VERSION } from '@/js/utils/constants';

export const prettyUrlHash = (str: string) =>
  str.replace(/\s+/g, '-').toLowerCase();

export const prettyUrlHashExtreme = (str: string) =>
  // Removes all non alpha characters then replaces spaces with hyphens
  str
    .replace(/[^\w\s]+/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();

export const getVersionLabel = (product: Product, version: Version) =>
  product.most_recent_version?.id === version.id
    ? LATEST_VERSION
    : version.label;
