import Alert from '@salesforce/design-system-react/components/alert';
import AlertContainer from '@salesforce/design-system-react/components/alert/container';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DowntimeAlert = ({ message }: { message: string }) => {
  const { t } = useTranslation();

  return (
    <AlertContainer className="downtime-alert">
      <Alert
        labels={{
          heading: `${t('Maintenance notice.')} ${message}`,
        }}
        onClickHeading={null}
        variant="warning"
      />
    </AlertContainer>
  );
};

export default DowntimeAlert;
