// Import WES Web Components
// import WESTextInput from '@salesforce-ux/wes-components/src/text-input/dist/text-input';
// import WESTabset from '@salesforce-ux/wes-components/src/tabset/dist/tabset';
// import WESTab from '@salesforce-ux/wes-components/src/tab/dist/tab';
// import WESPanel from '@salesforce-ux/wes-components/src/tab-panel/dist/tab-panel';
import WESBadge from '@salesforce-ux/wes-components/src/badge/dist/badge';
import WESButton from '@salesforce-ux/wes-components/src/button/dist/button';
import WESCard from '@salesforce-ux/wes-components/src/card/dist/card';
// import WESSelect from '@salesforce-ux/wes-components/src/select/dist/select';
import WESDivider from '@salesforce-ux/wes-components/src/divider/dist/divider';
import WESIcon from '@salesforce-ux/wes-components/src/icon/dist/icon';
import WESImage from '@salesforce-ux/wes-components/src/image/dist/image';

// Workaround to allow WES Grid style https://www.npmjs.com/package/@salesforce-ux/wes-grid
// and prevent typing errors on all other WES component
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    alt?: string | number | undefined;
    src?: string | number | undefined;
    direction?: string | number | undefined;
    grid?: string | number | undefined;
    size?: string | number | undefined;
    axis?: string | number | undefined;
    gap?: string | number | undefined;
    media?: string | number | undefined;
    depth?: string | number | undefined;
    width?: string | number | undefined;
    symbol?: string | number | undefined;
    'type-style'?: string | number | undefined;
  }
}

// Declare custom elements of all WES components being used
window.customElements.define('wes-button', WESButton);
window.customElements.define('wes-card', WESCard);
window.customElements.define('wes-image', WESImage);
window.customElements.define('wes-icon', WESIcon);
// window.customElements.define('wes-text-input', WESTextInput);
// window.customElements.define('wes-tabset', WESTabset);
// window.customElements.define('wes-tab', WESTab);
// window.customElements.define('wes-panel', WESPanel);
window.customElements.define('wes-badge', WESBadge);
// window.customElements.define('wes-select', WESSelect);
window.customElements.define('wes-divider', WESDivider);

// register "wes-button" for JSX
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // register "wes-button" for JSX
      'wes-button': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'wes-card': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'wes-image': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'wes-icon': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      // 'wes-text-input': React.DetailedHTMLProps<
      //   React.HTMLAttributes<HTMLElement>,
      //   HTMLElement
      // >;
      // 'wes-tabset': React.DetailedHTMLProps<
      //   React.HTMLAttributes<HTMLElement>,
      //   HTMLElement
      // >;
      // 'wes-tab': React.DetailedHTMLProps<
      //   React.HTMLAttributes<HTMLElement>,
      //   HTMLElement
      // >;
      // 'wes-panel': React.DetailedHTMLProps<
      //   React.HTMLAttributes<HTMLElement>,
      //   HTMLElement
      // >;
      'wes-badge': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      // 'wes-select': React.DetailedHTMLProps<
      //   React.HTMLAttributes<HTMLElement>,
      //   HTMLElement
      // >;
      'wes-divider': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
