/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Link } from 'react-router-dom';

import Banner from '@/img/product-banner.png';
import routes from '@/js/utils/routes';

const bannerBkg = {
  backgroundImage: `url(${Banner})`,
};

const ProductBanner = (props: {
  pageType: string;
  title: string;
  subtitle: string;
  backLinkText: string;
}) => (
  <div className="product-banner-container">
    <div className="banner slds-grid slds-is-relative" style={bannerBkg}>
      <p type-style="body-2" className="slds-is-absolute">
        <Link to={routes.landing()} className="back-link">
          <wes-icon symbol="chevronleft"></wes-icon>
          {props.backLinkText}
        </Link>
      </p>
      <div className="slds-align-middle slds-size_3-of-3 slds-large-size_2-of-3">
        <p
          type-style="display-8"
          className="slds-m-bottom_medium text-transform_uppercase"
        >
          {props.pageType}
        </p>
        <h1 type-style="display-3" className="slds-m-bottom_medium">
          {props.title}
        </h1>
        <p
          type-style="body-1"
          dangerouslySetInnerHTML={{
            __html: props.subtitle,
          }}
        ></p>
      </div>
    </div>
  </div>
);

export default ProductBanner;
