import Avatar from '@salesforce/design-system-react/components/avatar';
import Icon from '@salesforce/design-system-react/components/icon';
import React, { CSSProperties } from 'react';

import { Product } from '@/js/store/products/reducer';
interface ProductIconProps {
  item: Product;
}

const ProductIcon = ({ item }: ProductIconProps) => {
  const { title, color, icon } = item;

  if (icon?.type === 'url' && icon?.url) {
    // Custom icon at provided URL
    return (
      <Avatar
        variant="entity"
        label={title}
        imgSrc={icon.url}
        imgAlt={title}
        title={title}
        size="large"
      />
    );
  }
  if (icon?.type === 'slds' && icon?.category && icon?.name) {
    // Custom SLDS svg icon
    return (
      <>
        <Icon
          assistiveText={{ label: title }}
          category={icon.category}
          name={icon.name}
          size="large"
        />
      </>
    );
  }
  if (color) {
    // Standard entity icon (initials) with custom color
    return (
      <div style={{ '--custom-color': color } as CSSProperties}>
        <Avatar variant="entity" label={title} size="large" />
      </div>
    );
  }
  // Standard entity icon (initials)
  return <Avatar variant="entity" label={title} size="large" />;
};

export default ProductIcon;
