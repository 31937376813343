const routes = {
  home: () => '/',
  product_list: () => '/solutions',
  product_detail: (productSlug: string) => `/solutions/${productSlug}`,
  version_detail: (productSlug: string, versionLabel: string) =>
    `/solutions/${productSlug}/${versionLabel}`,
  plan_detail: (productSlug: string, versionLabel: string, planSlug: string) =>
    `/solutions/${productSlug}/${versionLabel}/${planSlug}`,
  job_detail: (
    productSlug: string,
    versionLabel: string,
    planSlug: string,
    jobId: string,
  ) => `/solutions/${productSlug}/${versionLabel}/${planSlug}/jobs/${jobId}`,

  landing: () => '/',
  solutions: () => '/solutions',

  solution_category: (categorySlug: string) => `/${categorySlug}`,
  solution_categories: (categorySlug: string, subCategorySlug: string) =>
    `/${categorySlug}/${subCategorySlug}`,

  product_details: (productSlug: string) => `/solutions/${productSlug}`,
  version_details: (productSlug: string, versionLabel: string) =>
    `/solutions/${productSlug}/${versionLabel}`,

  // install_product: () => '/install',
};

export const routePatterns = {
  home: () => '/',
  auth_error: () => '/accounts/*',
  product_list: () => '/solutions',
  product_detail: () => '/solutions/:productSlug',
  version_detail: () => '/solutions/:productSlug/:versionLabel',
  plan_detail: () => '/solutions/:productSlug/:versionLabel/:planSlug',
  job_detail: () =>
    '/solutions/:productSlug/:versionLabel/:planSlug/jobs/:jobId',

  landing: () => '/',
  solutions: () => '/solutions',

  solution_category: () => '/:categorySlug',
  solution_categories: () => '/:categorySlug/:subCategorySlug',

  product_details: () => '/products/:productSlug',
  version_details: () => '/products/:productSlug/:versionLabel',

  // install_product: () => '/install',
};

export default routes;
