import '@/js/wes/wes-react';

import React from 'react';
import { Link } from 'react-router-dom';

import Automotive from '@/img/Automotive.png';
import { Category } from '@/js/store/products/reducer';
import { prettyUrlHashExtreme } from '@/js/utils/helpers';
import routes from '@/js/utils/routes';
interface ProductCardProps {
  category: Category;
}

const CategoryCard = ({ category }: ProductCardProps) => (
  <Link
    className="industry-card slds-text-link_reset slds-m-around_large"
    to={routes.solution_category(prettyUrlHashExtreme(category.title))}
  >
    <div className="slds-col slds-grid slds-grid_vertical">
      <wes-card className="slds-col">
        <wes-image
          src={category.image || Automotive}
          alt={category.title}
          width="40"
        ></wes-image>
      </wes-card>
      <p className="slds-col industry-title slds-m-top_small slds-text-align_center">
        {category.title}
      </p>
    </div>
  </Link>
);

export default CategoryCard;
