/* eslint-disable react/no-unknown-property */
/* eslint-disable react-hooks/rules-of-hooks */
import Spinner from '@salesforce/design-system-react/components/spinner';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import desertSvg from '@/img/desert.svg?raw';
import FourOhFour from '@/js/components/404';
import Header from '@/js/components/redesign/header';
import ProductBanner from '@/js/components/redesign/productBanner';
import SolutionCard from '@/js/components/redesign/solutionCard';
import { useIsMounted } from '@/js/components/utils';
import { AppState } from '@/js/store';
import { ThunkDispatch } from '@/js/store';
import { fetchMoreProducts } from '@/js/store/products/actions';
import { Category, Product } from '@/js/store/products/reducer';
import {
  CategoryWithProducts,
  selectParentCategory,
  selectProductCategories,
  selectProductsInCategory,
  selectSubCategoriesWithProducts,
} from '@/js/store/products/selectors';
import { prettyUrlHashExtreme } from '@/js/utils/helpers';
import routes from '@/js/utils/routes';

const ProductSolutions = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch<ThunkDispatch>();
  const isMounted = useIsMounted();

  const [fetchingProducts, setFetchingProducts] = useState(false);

  const page_title = window.GLOBALS?.SITE?.name || t('MetaDeploy'); // default for page title
  // const { categoryId } = useParams<{ categoryId: string }>();
  const { categorySlug } = useParams<{ categorySlug: string }>();
  const { subCategorySlug } = useParams<{ subCategorySlug: string }>();

  const allCategories: Category[] = useSelector((state: AppState) =>
    selectProductCategories(state),
  );
  const categoryId: number | undefined = allCategories.find(
    (cat: Category) => prettyUrlHashExtreme(cat.title) === categorySlug,
  )?.id;

  if (!categoryId) {
    return (
      <FourOhFour
        message={
          <Trans i18nKey="productNotFound">
            We can’t find the product you’re looking for. Try the{' '}
            <Link to={routes.landing()}>list of all products</Link>?
          </Trans>
        }
      />
    );
  }

  const subCategoryId: number | undefined = allCategories.find(
    (cat: Category) => prettyUrlHashExtreme(cat.title) === subCategorySlug,
  )?.id;

  const { title, description } = useSelector((state: AppState) =>
    selectParentCategory(state, categoryId),
  );

  const subCategoriesWithProducts: CategoryWithProducts[] = useSelector(
    (state: AppState) => selectSubCategoriesWithProducts(state, categoryId),
  );

  const productsInCategory: Product[] = useSelector((state: AppState) =>
    selectProductsInCategory(state, categoryId),
  );

  const redirectToSubcategory = (index: number) => {
    history.replace(
      routes.solution_categories(
        categorySlug,
        prettyUrlHashExtreme(subCategoriesWithProducts[index].category.title),
      ),
    );
  };

  const checkForSubCategories = () => {
    // We have subcategories with products to redirect to sub category page
    if (
      categoryId &&
      !subCategorySlug &&
      subCategoriesWithProducts.length > 0
    ) {
      redirectToSubcategory(0);
    }
    return null;
  };

  checkForSubCategories();

  const activeCategory = subCategoryId
    ? subCategoriesWithProducts.find(
        ({ category }) => category.id === subCategoryId,
      )?.category
    : allCategories.find((category: Category) => category.id === categoryId);
  const moreProductsUrl = activeCategory?.next;
  const maybeFetchMoreProducts = () => {
    if (activeCategory && moreProductsUrl && !fetchingProducts) {
      /* istanbul ignore else */
      if (isMounted.current) {
        setFetchingProducts(true);
      }
      dispatch(
        fetchMoreProducts({
          url: moreProductsUrl,
          id: activeCategory.id,
        }),
      ).finally(() => {
        /* istanbul ignore else */
        if (isMounted.current) {
          setFetchingProducts(false);
        }
      });
    }
  };

  // Fetch more products (if there are more) after scrolling to bottom of list
  /* useEffect(() => {
    const scrollChanged = scrollY !== prevScrollY.current;
    prevScrollY.current = scrollY;

    if (fetchingProducts || !scrollChanged) {
      return;
    }

    // istanbul ignore next
    const scrollHeight =
      document.documentElement?.scrollHeight ||
      document.body?.scrollHeight ||
      Infinity;
    const clientHeight =
      document.documentElement?.clientHeight || window.innerHeight;
    // Fetch more products if within 100px of bottom of page...
    const scrolledToBottom =
      scrollHeight - Math.ceil(scrollY + clientHeight) <= 100;

    // istanbul ignore else
    if (scrolledToBottom) {
      maybeFetchMoreProducts();
    }
  }, [
    dispatch,
    fetchingProducts,
    isMounted,
    productsInCategory,
    subCategoriesWithProducts,
    scrollY,
  ]);*/

  const displayCardList = (list: Product[]) => (
    <>
      {list.length === 0 ? (
        <div className="slds-illustration slds-illustration_large">
          <div
            className="slds-m-vertical_xx-large"
            dangerouslySetInnerHTML={{ __html: desertSvg }}
          />
          <h3 className="slds-illustration__header slds-text-heading_medium">
            {t('Coming Soon')}
          </h3>
          <p className="slds-text-body_regular">
            {t('Solutions in this category are coming soon.')}
          </p>
        </div>
      ) : (
        <div className="slds-grid slds-grid_align-center slds-gutters_direct-x-large slds-wrap">
          {list?.map((product: Product) => (
            <div
              className="slds-col slds-m-bottom_xx-large slds-grow-none"
              key={product.id}
            >
              <SolutionCard product={product} />
            </div>
          ))}
        </div>
      )}
    </>
  );

  return (
    <>
      <Helmet>
        <title>{`${title} | ${page_title}`}</title>
      </Helmet>
      <Header history={history} />
      <ProductBanner
        pageType="Solutions"
        title={title}
        subtitle={description}
        backLinkText="View all products"
      />
      {subCategorySlug ? (
        <Tabs
          className="product-category-tabs"
          selectedIndex={subCategoriesWithProducts.findIndex(
            (item: CategoryWithProducts) => item.category.id === subCategoryId,
          )}
          onSelect={(i: number) => {
            redirectToSubcategory(i);
          }}
        >
          {subCategoriesWithProducts?.map((item: CategoryWithProducts) => (
            <TabsPanel label={item.category.title} key={item.category.id}>
              <div className="content-main slds-container_x-large slds-container_center slds-p-horizontal_medium slds-p-bottom_x-large">
                <div className="slds-grid slds-grid_align-center slds-gutters">
                  <div className="section-intro slds-col slds-text-align_center slds-size_8-of-12">
                    <h2 type-style="display-5" className="slds-p-bottom_medium">
                      {item.category.title}
                    </h2>
                    <p
                      type-style="body-1"
                      dangerouslySetInnerHTML={{
                        __html: item.category.description,
                      }}
                    ></p>
                  </div>
                </div>
                {displayCardList(item.products)}
              </div>
            </TabsPanel>
          ))}
        </Tabs>
      ) : (
        <div className="content-main slds-container_x-large slds-container_center slds-m-top_x-large slds-p-horizontal_medium slds-p-top_xx-large slds-p-bottom_x-large">
          {displayCardList(productsInCategory)}
        </div>
      )}
      <div className="slds-container_x-large slds-container_center slds-text-align_center">
        {!fetchingProducts && moreProductsUrl && (
          <wes-button onClick={maybeFetchMoreProducts}>
            {t('Load more')} &nbsp;
            <wes-icon symbol="chevrondown"></wes-icon>
          </wes-button>
        )}
        {fetchingProducts && (
          <>
            <span className="slds-is-relative slds-m-right_large">
              <Spinner variant="brand" size="small" />
            </span>
            {t('Loading…')}
          </>
        )}
      </div>
    </>
  );
};

export default ProductSolutions;
