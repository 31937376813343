import React, { useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const { action } = useHistory();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (action === 'REPLACE') {
      return;
    }
    window.scrollTo(0, 0);
  }, [action, pathname]);

  return null;
};

export default ScrollToTop;
