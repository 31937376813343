import React from 'react';

import footerBackgroundImage from '@/img/footer-curve.svg?raw';
const footBkg = {
  backgroundImage: `url('data:image/svg+xml;utf8,${encodeURIComponent(
    footerBackgroundImage,
  )}')`,
};

const Footer = () => {
  const copyright_text = window.GLOBALS.SITE?.copyright_notice;
  return (
    <>
      <div
        className="footer-display-image"
        aria-hidden="true"
        style={footBkg}
      />
      <footer
        className="site-contentinfo
      slds-grid
      slds-grid_vertical-align-center
      slds-gutters
      slds-no-wrap
      slds-text-body_small"
      >
        <div className="logo-col">
          <svg
            width="46"
            height="32"
            viewBox="0 0 46 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_312_23586)">
              <path
                d="M18.8695 3.49396C20.3184 1.94856 22.3669 1.00787 24.6152 1.00787C27.613 1.00787 30.2111 2.68766 31.6101 5.19055C32.8259 4.65302 34.1582 4.35066 35.5572 4.35066C40.9532 4.35066 45.3333 8.8021 45.3333 14.295C45.3333 19.7879 40.9532 24.2394 35.5572 24.2394C34.891 24.2394 34.2581 24.1722 33.6253 24.0378C32.4095 26.2383 30.0612 27.7333 27.3965 27.7333C26.2807 27.7333 25.2148 27.4646 24.2655 27.011C23.0164 29.9507 20.1352 32.0168 16.771 32.0168C13.2569 32.0168 10.2758 29.7827 9.12662 26.6415C8.62699 26.7423 8.1107 26.8095 7.57776 26.8095C3.38085 26.7759 0 23.3323 0 19.0656C0 16.21 1.51555 13.7239 3.78055 12.3801C3.31423 11.305 3.06441 10.1123 3.06441 8.85249C3.06441 3.9643 6.99486 0 11.8579 0C14.7225 0 17.254 1.36063 18.8695 3.49396Z"
                fill="#0D9DDA"
              />
            </g>
            <defs>
              <clipPath id="clip0_312_23586">
                <rect width="45.3333" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          className="slds-col"
          dangerouslySetInnerHTML={{ __html: copyright_text }}
        ></div>
      </footer>
    </>
  );
};

export default Footer;
